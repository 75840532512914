<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="d-flex">
          <span class="d-flex justify-center align-center">
            <img
              src="@/assets/icons/faceMatch-1-1.svg"
              class="faceMatchIcon"
              alt="age-icon"
            />
          </span>
          <div class="ml-2">
            <span class="main-heading">Face Match</span>
            <v-icon
              v-if="!infoText"
              @click="infoText = true"
              class="info-icon cursor-pointer ml-2"
              >mdi-information-outline</v-icon
            >
          </div>
        </v-col>
        <v-col cols="12" class="" v-if="infoText">
          <v-alert color="age-alert" class="alert-card">
            <template v-slot:append>
              <img
                src="@/assets/icons/close-icon.svg"
                @click="infoText = false"
                class="cursor-pointer"
                height="22"
                width="22"
                alt="close-icon"
              />
            </template>
            <span class="info-text-card">
              Effortlessly and securely match a selfie or any face image against
              an ID document or another image. Choose to upload an image for
              comparison. If you need to compare one face with multiple images,
              our

              <router-link
                class="info-text-card-link"
                id="nav-item-live-perform-ocr"
                target="_blank"
                to="face-search"
              >
                'Face Search'
              </router-link>
              service is just a click away.
            </span>
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="secnod-row">
        <!-- selfie card -->
        <v-col
          v-if="!showResultDiv"
          :cols="$vuetify.breakpoint.mdAndDown ? 12 : 5"
          class="d-flex justify-center"
        >
          <v-card class="selfie-search-card">
            <div class="step-retry">
              <p class="card-step">Step 1</p>
              <p
                v-if="imageSrc && !documentImage"
                class="card-retry cursor-pointer"
                @click="retry"
              >
                Retry
                <img
                  src="@/assets/icons/retry-blue.svg"
                  height="12"
                  width="12"
                  alt="retry"
                  class="ml-1"
                />
              </p>
            </div>

            <v-card-title class="card-title pt-0">Selfie Image</v-card-title>
            <v-card-text v-if="imageSrc" class="card-body-img">
              <img
                class="preview-img"
                id="image-preview"
                :src="imageSrc"
                alt=""
              />
            </v-card-text>
            <v-card-text v-else class="card-body">
              <div
                v-if="!imageSrc"
                class="d-flex cursor-pointer"
                @click="browseClick('selfie')"
              >
                <img
                  src="@/assets/icons/file-upload.svg"
                  height="20"
                  width="20"
                  alt="file-upload-icon"
                />
                <div class="option-text">Upload Selfie</div>
              </div>
              <p v-if="!imageSrc" cols="6" class="file-limit-text">
                Maximum size 3 MB
              </p>
              <v-divider
                v-if="!imageSrc"
                class="horizontal-divider"
              ></v-divider>
              <div
                v-if="!imageSrc"
                class="d-flex cursor-pointer"
                @click="startSelfie"
              >
                <img
                  src="@/assets/icons/camera-icon.svg"
                  height="20"
                  width="20"
                  alt="camera-icon"
                />
                <p class="option-text">Take Selfie</p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-if="!showResultDiv && !$vuetify.breakpoint.mdAndDown"
          cols="2"
          class="horizontal-divider-main"
        >
          <hr class="dashed-divider" />
        </v-col>
        <!-- selfie/document card -->
        <v-col
          v-if="!showResultDiv"
          :cols="$vuetify.breakpoint.mdAndDown ? 12 : 5"
          class="d-flex justify-center"
        >
          <v-card
            class="selfie-search-card"
            :class="{ 'disable-view': !imageSrc }"
          >
            <p class="card-step">Step 2</p>
            <v-card-title class="card-title pt-0">Document/Selfie</v-card-title>
            <v-card-text v-if="documentImage" class="card-body-img">
              <img
                class="preview-img"
                id="image-preview"
                :src="documentImage"
                alt=""
              />
            </v-card-text>
            <v-card-text v-else class="card-body">
              <div
                @click="browseClick('document')"
                v-if="!documentImage"
                class="d-flex"
                :class="{ 'cursor-pointer': imageSrc }"
              >
                <img
                  src="@/assets/icons/file-upload.svg"
                  height="20"
                  width="20"
                  alt="file-upload-icon"
                />
                <div class="option-text">Upload Selfie</div>
              </div>
              <p v-if="!documentImage" cols="6" class="file-limit-text">
                Maximum size 3 MB
              </p>
              <v-divider
                v-if="!documentImage"
                class="horizontal-divider"
              ></v-divider>
              <div
                v-if="!documentImage"
                class="d-flex"
                :class="{ 'cursor-pointer': imageSrc }"
                @click="startDocumentSelfie"
              >
                <img
                  src="@/assets/icons/camera-icon.svg"
                  height="20"
                  width="20"
                  alt="camera-icon"
                />
                <p class="option-text">Capture Document/Selfie</p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Result Card -->
        <v-col v-if="showResultDiv" cols="12" class="d-flex justify-center">
          <v-card class="selfie-search-card-result">
            <v-card-title class="card-title">Face Match Result</v-card-title>
            <div class="flex-cards">
              <v-card-text class="card-body-img">
                <img class="preview-img" id="image-preview" :src="imageSrc" />
              </v-card-text>
              <v-card-text class="card-body-img">
                <img
                  class="preview-img"
                  id="image-preview"
                  :src="documentImage"
                />
              </v-card-text>
            </div>
            <v-card-actions class="card-footer">
              <div v-if="showResult">
                Face similarity score:
                <span class="substr-text">
                  {{ getSimilarityScore }}% Matched</span
                >
              </div>
              <div v-if="isProcessing">
                <v-progress-circular
                  :width="3"
                  color="#173dd0"
                  indeterminate
                ></v-progress-circular>
                <span class="pl-2"> Processing Data...</span>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="isProcessing">
        <v-col cols="12" class="text-center mt-2">
          <div>
            <v-progress-circular
              :width="3"
              color="#173dd0"
              indeterminate
            ></v-progress-circular>
            <span class="pl-2"> Processing Data...</span>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="showResult" class="mt-6">
        <v-col cols="12" class="d-flex justify-center result-btns">
          <v-btn
            depressed
            class="view-result"
            :to="`/transactions/${this.referenceId}`"
            target="_blank"
          >
            <span class="px-1">View Results</span>
            <img
              src="@/assets/icons/arrow-next.svg"
              height="15"
              width="15"
              alt="next-arrow"
            />
          </v-btn>
          <v-btn @click="retry" depressed class="try-more">
            <span class="px-1">Try More</span>
            <img
              src="@/assets/icons/retry-icon.svg"
              height="12"
              width="12"
              alt="next-arrow"
            />
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="retryBtn" class="mt-6">
        <v-col cols="12" class="d-flex justify-center result-btns">
          <v-btn @click="retry" depressed class="try-more">
            <span class="px-1">Retry</span>
            <img
              src="@/assets/icons/retry-icon.svg"
              height="12"
              width="12"
              alt="next-arrow"
            />
          </v-btn>
        </v-col>
      </v-row>
      <!-- camera & preview -->
      <camera
        v-if="startSelfieCamera"
        @attached-image="getImage"
        @close-camera-dialog="closeCamera"
      ></camera>
      <input
        ref="file-uploader"
        id="upload-file"
        class="d-none"
        type="file"
        accept="image/jpeg,image/JPEG,image/jpg,image/JPG,image/PNG,image/png"
        @change="handleImageUpload($event)"
      />
      <camera
        v-if="startDocumentCamera"
        @attached-image="getDocumentImage"
        @close-camera-dialog="closeCamera"
      ></camera>
      <input
        ref="file-uploader-document"
        id="upload-file-document"
        class="d-none"
        type="file"
        accept="image/jpeg,image/JPEG,image/jpg,image/JPG,image/PNG,image/png"
        @change="DocumentFileUpload($event)"
      />
    </v-container>
  </div>
</template>

<script>
import Camera from "../common/Camera.vue";
import ApiService from "../../services/Api";
import {
  showErrorMessage,
  showSimpleErrorMessage,
} from "../../utils/showError";

export default {
  name: "OneToOne",
  components: { Camera },
  data() {
    return {
      startSelfieCamera: false,
      startDocumentCamera: false,
      disabledStartDocumentCamera: true,
      selfieCameraBlob: null,
      documentCameraBlob: null,
      similarityScore: null,
      isProcessing: false,
      showResult: false,
      selfieImage: "",
      documentImage: "",
      fileType: "",
      referenceId: null,
      isPhotoTaken: false,
      infoText: true,
      imageSrc: null,
      startCamera: false,
      similarityStatus: false,
      showResultDiv: false,
      fileSizeLimit: 3 * 1024 * 1024,
      retryBtn: false,
    };
  },
  computed: {
    getSimilarityScore() {
      return parseInt(this.similarityScore, 10);
    },
  },
  methods: {
    /**
     * Sets the image source and blob for the captured image.
     * Enables starting the document camera.
     * @param {Object} data - Object containing the base64Image and file of the captured image.
     */
    getImage(data) {
      this.imageSrc = data.base64Image;
      this.disabledStartDocumentCamera = false;
      this.selfieCameraBlob = data.file;
    },

    /**
     * Sets the document image source and blob for the captured document image.
     * Initiates the process of comparing the document image.
     * @param {Object} data - Object containing the base64Image and file of the captured document image.
     */
    getDocumentImage(data) {
      this.documentImage = data.base64Image;
      this.documentCameraBlob = data.file;
      this.sendForComparing();
    },
    /**
     * @Description
     * 1. this method is used to intilize the browse click
     * @param none
     * @return none
     **/
    browseClick(e) {
      if (e == "selfie") this.$refs?.["file-uploader"]?.click();
      else if (!this.disabledStartDocumentCamera)
        this.$refs?.["file-uploader-document"]?.click();
    },

    /**
     * Reloads the page.
     */
    goBack() {
      location.reload();
    },

    /**
     * Initiates the process of capturing a selfie.
     * Disables starting the document camera.
     * Sets the flag indicating that a photo has been taken.
     */
    startSelfie() {
      this.startSelfieCamera = true;
      this.disabledStartDocumentCamera = true;
      this.isPhotoTaken = true;
    },

    /**
     * Initiates the process of capturing a document selfie if the document camera is not disabled.
     */
    startDocumentSelfie() {
      if (!this.disabledStartDocumentCamera) {
        this.startDocumentCamera = true;
        this.isPhotoTaken = true;
      }
    },

    /**
     * Navigates to the transaction results page.
     */
    showResults() {
      this.$router.push({ path: `/transactions/${this.referenceId}` });
    },

    /**
     * Sets the flag indicating whether a photo has been taken.
     * @param {boolean} value - Flag indicating whether a photo has been taken.
     */
    isCaptured(value) {
      this.isPhotoTaken = value;
    },

    /**
     * Handles the event when a selfie is captured.
     * Enables the document camera.
     * Sets the blob of the captured selfie.
     * @param {Blob} blob - Blob of the captured selfie.
     */
    selfieCaptured(blob) {
      this.disabledStartDocumentCamera = false;
      this.selfieCameraBlob = blob;
    },

    /**
     * Handles the event when an image is uploaded.
     * Checks if the uploaded image size is within the limit.
     * Sets the file type and image source.
     * Reads the uploaded file as an array buffer and sets the blob of the uploaded image.
     * Sets the flag indicating that a photo has been taken.
     * @param {Event} event - Event object containing information about the image upload.
     */
    handleImageUpload(event) {
      if (
        event?.target?.files[0] &&
        event?.target?.files[0]?.size < this.fileSizeLimit
      ) {
        const file = event.target.files[0];
        this.fileType = file.type;
        this.imageSrc = URL.createObjectURL(file);
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
          const blob = new Blob([reader.result], { type: file.type });
          this.selfieCaptured(blob);
        };
        this.isPhotoTaken = true;
      } else {
        showSimpleErrorMessage("image size cannot be greater than 3 mb.");
      }
    },

    /**
     * Handles the event when a document file is uploaded.
     * Checks if the uploaded file size is within the limit.
     * Sets the document image source.
     * Reads the uploaded file as an array buffer and calls the documentCapture method.
     * @param {Event} event - Event object containing information about the document file upload.
     */
    DocumentFileUpload(event) {
      if (
        event?.target?.files[0] &&
        event?.target?.files[0]?.size < this.fileSizeLimit
      ) {
        const file = event.target.files[0];
        this.documentImage = URL.createObjectURL(file);
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
          const blob = new Blob([reader.result], { type: file.type });
          this.documentCapture(blob);
        };
      } else {
        showSimpleErrorMessage("image size cannot be greater than 3 mb.");
      }
    },

    /**
     * Handles the event when a document is captured.
     * Sets the blob of the captured document.
     * Calls the sendForComparing method.
     * @param {Blob} blob - Blob of the captured document.
     */
    documentCapture(blob) {
      this.documentCameraBlob = blob;
      this.sendForComparing();
    },

    /**
     * Sends the captured document and selfie images for comparison.
     * Makes a request to the server with the necessary data.
     * Updates processing status and displays the result.
     */
    sendForComparing() {
      this.isProcessing = true;
      ApiService.GetRequest("/merchant/client")
        .then((res) => {
          const formData = new FormData();
          formData.append("type", "photo_id_match");
          formData.append("id_frame", this.documentCameraBlob, "file.jpeg");
          formData.append("face_frame", this.selfieCameraBlob, "file.jpeg");
          ApiService.PostRequest(
            "face-match",
            formData,
            "iframe",
            res.data.result.data.client_secret
          )
            .then((res) => {
              this.isProcessing = false;
              this.showResult = true;
              this.showResultDiv = true;
              this.referenceId = res.data.result.data.reference_id;
              this.similarityScore =
                res.data.result.data.similarity_score * 100;
              if (res.data.result.data.similarity_status == 1)
                this.similarityStatus = true;
              else this.similarityStatus = false;
            })
            .catch((error) => {
              showErrorMessage(error);
              this.retryBtn = true;
              this.isProcessing = false;
              if (error.response.status === 400) {
                setTimeout(this.retry.bind(this), 2500);
              }
            });
        })
        .catch((error) => {
          this.retryBtn = true;
          this.isProcessing = false;
          showErrorMessage(error);
        });
    },

    /**
     * Reloads the page to retry the process.
     */
    retry() {
      location.reload();
    },

    /**
     * Closes the selfie camera and document camera.
     */
    closeCamera() {
      this.startSelfieCamera = false;
      this.startDocumentCamera = false;
    },

    /**
     * Checks if a video is uploaded based on the file type.
     * @param {string} file_type - The file type of the uploaded file.
     * @returns {boolean} - Returns true if the uploaded file is a video; otherwise, returns false.
     */
    isVideoUploaded(file_type) {
      const fileExtension = file_type.split("/").pop().toLowerCase();
      const videoExtensions = ["mp4", "mov", "quicktime"];
      return videoExtensions.includes(fileExtension) ? true : false;
    },
  },
};
</script>

<style scoped>
@media (min-width: 600px) and (max-width: 746px) {
  .sec-font {
    font-size: 18px !important;
    margin-bottom: 6px;
  }
}
.container {
  margin-top: 2rem;
}
.img-container {
  height: 300px;
  /* width: 500px; */
  overflow: hidden;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.captured-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.img-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.info-text-card {
  width: 50rem;
  color: #000;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.info-text-card-link {
  color: #213cc8;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  text-decoration: none;
}

.main-heading {
  color: #292b30;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem;
  /* 150% */
}

.age-main-container {
  padding: 40px;
}
.age-alert {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fafafa;
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.age-alert /deep/ .v-alert__wrapper {
  align-items: start;
}

.age-alert /deep/ .v-alert__content {
  text-align: justify;
  padding-right: 40px;
}

.selfie-search-card {
  width: 335px;
  height: 310px;
  padding: 0 16px;
  border-radius: 0.625rem;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.selfie-search-card-result {
  width: 65%;
  height: 19.375rem;
  padding: 0 16px;
  border-radius: 0.625rem;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.card-title {
  color: #292b30;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem;
  display: flex;
  justify-content: center;
}

.card-body {
  width: 302px;
  height: 209px;
  border-radius: 0.375rem;
  border: 1px dashed #e3e3e3;
  background: #f7f7f7;
  border: 5.06rem 1rem 1.81rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.card-body-img {
  width: 302px;
  height: 209px;
}
.card-body-img img {
  border-radius: 0.375rem;
  border: 1px dashed #e3e3e3;
}
.card-footer {
  display: flex;
  justify-content: center;
  color: #909295;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.step-retry {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.card-step {
  color: #a6a2a2;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  padding-top: 5px;
  margin-bottom: 0;
}
.card-retry {
  color: #213cc8;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  padding-top: 5px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.file-limit-text {
  color: #909295;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 0 0;
}

.horizontal-divider {
  width: 54px;
  height: 20px;
  min-height: 20px;
  color: #909295;
}
.horizontal-divider-main {
  display: flex;
  align-items: center;
}
.dashed-divider {
  content: "";
  display: block;
  border: 1px dashed #e3e3e3; /* Adjust color and style as needed */
  height: 1px;
  width: 100%;
}
.option {
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 10px;
  padding: 0 0 5px;
}

.option-child {
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 10px;
  cursor: pointer;
}

.option-text {
  color: #213cc8;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 8px;
}

.substr-text {
  color: #213cc8;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 0 2px;
}

.preview-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 6px;
}

.result-btns {
  column-gap: 16px;
}

.result-btns .view-result {
  display: flex;
  column-gap: 4px;
  color: #000;
  height: 32px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.result-btns .try-more {
  display: flex;
  column-gap: 4px;
  width: 110px;
  height: 32px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #213cc8 !important;
  color: #ffffff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.secnod-row {
  padding-top: 10px;
}

.alert-card {
  flex-shrink: 0;
  border-radius: 0.375rem;
  border: 1px solid #e3e3e3;
  background: #fafafa;
}

.top-row {
  margin: 2rem 0 0 1.5rem;
}

.faceMatchIcon {
  width: 1.375rem;
  height: 1.375rem;
}
.selfie-match-text {
  font-size: 18px;
}

.info-icon {
  font-size: 16px;
  margin-top: -4px; /* Adjust the margin-top to align the icon properly */
}
.disable-view {
  opacity: 0.5;
  cursor: not-allowed;
}
.flex-cards {
  display: flex;
  justify-content: space-evenly;
}
</style>
<style lang="scss" scoped>
@use "../../assets/scss/breakpoints.scss" as b;
@include b.narrower-than(b.$bp-large) {
  .card-body {
    width: 95%;
  }
  .flex-cards {
    display: block;
  }
  .selfie-search-card-result {
    width: 95%;
    height: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
